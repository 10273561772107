var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('h2',{staticClass:"mb-4"},[_vm._v("ADI and Spec Grading Quiz")]),_c('p',{staticClass:"mb-3"},[_c('b',[_vm._v("Using the Course Syllabus")]),_vm._v(", fill in the missing steps for the resubmission of an assignment: ")]),_c('p',{staticClass:"mb-6"},[_vm._v(" After receiving a score on an assignment that you would like to resubmit, you should "),_c('v-select',{staticClass:"d-sm-inline-block ml-1 mr-1 my-n3",staticStyle:{"width":"400px"},attrs:{"items":_vm.options1,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" After this, a resubmission assignment will unlock. If you decide you do not want to resubmit, you should "),_c('v-select',{staticClass:"d-sm-inline-block ml-1 mr-1 my-n3",staticStyle:{"width":"240px"},attrs:{"items":_vm.options2,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-6"},[_vm._v(" You must resubmit "),_c('v-select',{staticClass:"d-sm-inline-block ml-1 mr-1 my-n3",staticStyle:{"width":"250px"},attrs:{"items":_vm.options3,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" If you resubmit, you should make changes to the original assignment and "),_c('v-select',{staticClass:"d-sm-inline-block ml-1 mr-1 my-n3",staticStyle:{"width":"250px"},attrs:{"items":_vm.options4,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Within a week, your token count will be adjusted by "),_c('v-select',{staticClass:"d-sm-inline-block ml-1 mr-1 my-n3",staticStyle:{"width":"170px"},attrs:{"items":_vm.options5,"item-text":"text","item-value":"value","label":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}}),_vm._v(" If you do not have a token to cover the resubmission, it will be deleted. When you resubmit, you need to email your TA or the Head TA. ")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }