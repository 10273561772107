<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">ADI and Spec Grading Quiz</h2>

      <p class="mb-3">
        <b>Using the Course Syllabus</b>, fill in the missing steps for the resubmission of an
        assignment:
      </p>

      <p class="mb-6">
        After receiving a score on an assignment that you would like to resubmit, you should

        <v-select
          v-model="inputs.input1"
          :items="options1"
          class="d-sm-inline-block ml-1 mr-1 my-n3"
          style="width: 400px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>

        After this, a resubmission assignment will unlock. If you decide you do not want to
        resubmit, you should

        <v-select
          v-model="inputs.input2"
          :items="options2"
          class="d-sm-inline-block ml-1 mr-1 my-n3"
          style="width: 240px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-6">
        You must resubmit
        <v-select
          v-model="inputs.input3"
          :items="options3"
          class="d-sm-inline-block ml-1 mr-1 my-n3"
          style="width: 250px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>

        If you resubmit, you should make changes to the original assignment and
        <v-select
          v-model="inputs.input4"
          :items="options4"
          class="d-sm-inline-block ml-1 mr-1 my-n3"
          style="width: 250px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-0">
        Within a week, your token count will be adjusted by
        <v-select
          v-model="inputs.input5"
          :items="options5"
          class="d-sm-inline-block ml-1 mr-1 my-n3"
          style="width: 170px"
          item-text="text"
          item-value="value"
          label=""
        >
          <template #item="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
          <template #selection="{item}">
            <span class="no-text-transform" v-html="item.text" />
          </template>
        </v-select>

        If you do not have a token to cover the resubmission, it will be deleted. When you resubmit,
        you need to email your TA or the Head TA.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UCIrvineAdiSpecGradingQuizQ9',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: 'use the token ATM use tokens to open the resubmission assignment.', value: 'a'},
        {text: 'email your TA.', value: 'b'},
        {text: 'resubmit over the old assignment.', value: 'c'},
        {text: "fill out the assignment's resubmission survey.", value: 'd'}, // correct
      ],
      options2: [
        {text: 'email the head TA.', value: 'a'},
        {text: 'retake the token resubmission survey.', value: 'b'}, // correct
        {text: 'speak with your TA.', value: 'c'},
        {text: 'post the issue on Ed Discussion.', value: 'd'},
      ],
      options3: [
        {text: 'within 3-4 days.', value: 'a'}, // correct
        {text: 'within a week by the end of the quarter.', value: 'b'},
      ],
      options4: [
        {text: 'highlight them.', value: 'a'},
        {text: 'resubmit without new formatting.', value: 'b'},
        {text: 'make the changed text bold and blue.', value: 'c'}, // correct
        {text: 'put all changes in one section.', value: 'd'},
      ],
      options5: [
        {text: 'my TA.', value: 'a'},
        {text: 'the Head TA.', value: 'b'}, // correct
        {text: 'Prof. Edwards.', value: 'c'},
        {text: 'Token ATM automatically.', value: 'd'},
      ],
    };
  },
};
</script>
<style scoped></style>
